/*jshint sub:true*/
jQuery(document).ready(function ($) {

    function getUrlVars() {
        var vars = [], hash;
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for (var i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
        return vars;
    }
    function urldecode(str) {
        return decodeURIComponent((str + '').replace(/\+/g, '%20'));
    }

    function billAnswers() {
        var question;
        var yesno = Math.floor(Math.random() * 2);
        var chosenAnswer = 'no';

        if (yesno === 0) {
            chosenAnswer = 'yes';
        }

        $('.ask').hide();

        if (document.location.search.length) {
            question = urldecode(getUrlVars()['q']);
        }

        $('.question, .answer').show();

        if (question.toLowerCase().indexOf('heaven') > -1) {
            $('.question > p > span').append(question);
            $('body').addClass('no');
            $('.answer > span').append('Hell no!');
        } else if (question.toLowerCase().indexOf('raise') > -1) {
            $('.question > p > span').append(question);
            $('body').addClass('no');
            $('.answer > span').append("Something stinks. Oh yea, it's your chances of getting a raise!");
        } else if (question.toLowerCase().indexOf('trump') > -1) {
            $('.question > p > span').append(question);
            $('body').addClass('trump');
            $('.answer > span').append("Make America Great Again!");
        } else {
            $('.question > p > span').append(question);
            $('body').addClass(chosenAnswer);
            $('.answer > span').append(answers[chosenAnswer][~~(Math.random() * answers[chosenAnswer].length)]);
        }
    }

    function search() {
        var root = location.protocol + '//' + location.host + '/?q=';
        var terms = $('.question-field').val();
        var searchUrl = root + terms;
        window.location.href = searchUrl;
    }

    function reset() {
        window.location.href = location.protocol + '//' + location.host;
    }

    var answers = {};
    answers['yes'] = ["Yes", "Yup", "Fo Sho", "Sure", "Absolutely", "That sounds good!", "Sure-ish", "Okey-dokey", "Affirmative", "Aye aye", "Roger", "10-4", "Right-o", "Uh-huh", "Very well", "Right on", "Ja", "Amen", "Totally", "Affirmative", "Amen", "Fine", "Good", "Okay", "True", "Yea", "All right", "Aye", "Beyond a doubt", "By all means", "Certainly", "Definitely", "Even so", "Exactly", "Gladly", "Good enough", "Granted", "Indubitably", "Just so", "Most assuredly", "Naturally", "Of course", "Positively", "Precisely", "Sure thing", "Surely", "Undoubtedly", "Unquestionably", "Very well", "Willingly", "Without fail"];
    answers['no'] = ["No way", "Nah", "No", "Don't think so.", "Yea, no", "Are you serious?", "Negatory", "Negative", "Uh-uh", "Nix", "Nope", "Nay", "Veto", "No siree", "For foul nor fair", "Not for all the tea in china", "Not in a million years", "Under no circumstances", "Not likely", "Not for joe", "Thumbs down", "Pigs might fly", "Fat chance", "Go fish"];

    if (document.location.search.length) {
        billAnswers();
    }

    $('.submit').on('click', function (e) {
        e.preventDefault();
    });

    $('.reset').click(function () {
        window.location.href = location.protocol + '//' + location.host;
    });

    $(document).keypress(function (e) {
        if ($('body').hasClass('yes') || $('body').hasClass('no')) {
            if (e.which == 13) {
                reset();
            }
        } else {
            if (e.which == 13) {
                if ($('.question-field').val().length === 0) {
                    $('.question-field').attr('placeholder', 'Ask a question first!');
                } else {
                    search();
                }
            }
        }
    });

});